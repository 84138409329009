<template>
  <section class="pt-4 px-0 px-xl-4 h-100">
    <b-container fluid>
      <b-row class="pb-3">
        <b-col cols="3">
          <b-select v-model="brandId" :options="brands" />
        </b-col>
        <b-col cols="2">
          <b-input
            id="filter-input"
            v-model="filter"
            type="search"
            placeholder="Cerca"
          />
        </b-col>
        <b-col cols="6" class="text-right">
          <h1>
            {{ brandName }}
            <b-badge variant="warning" class="text-dark">{{
              currencyFormatter(total)
            }}</b-badge>
            <span v-if="totalCommitted > 0" class="font-italic"
              >({{ currencyFormatter(totalCommitted) }})</span
            >
          </h1>
        </b-col>
      </b-row>
      <b-row>
        <b-table
          striped
          hover
          :fields="fields"
          :items="itemsWithTotal"
          :busy="isBusy"
          :filter="filter"
          class="mt-3"
          outlined
          sticky-header="75vh"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle m-2" />
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(arrivalDate)="data">
            {{ showDate(data.item.arrivalDate) }}
          </template>
          <template #cell(lastOrderDate)="data">
            {{ showDate(data.item.lastOrderDate) }}
          </template>
          <template #cell(costPrice)="data">
            {{ currencyFormatter(data.item.costPrice) }}
          </template>
          <template #cell(goodOnHand)="data">
            {{ data.item.goodOnHand }}
            <span v-if="data.item.purchaseQuantity > 0" class="font-italic"
              >({{ data.item.purchaseQuantity }})</span
            >
          </template>
          <template #cell(totalRow)="data">
            {{ currencyFormatter(data.item.totalRow) }}
            <span v-if="data.item.purchaseQuantity > 0" class="font-italic"
              >({{
                currencyFormatter(
                  data.item.costPrice * data.item.purchaseQuantity
                )
              }})</span
            >
          </template>
        </b-table>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "SupplyManager",
  data() {
    return {
      sortBy: "totalRow",
      sortDesc: true,
      fields: [
        { key: "code", label: "Codice", sortable: true },
        { key: "name", label: "Descrizione" },
        {
          key: "arrivalDate",
          label: "Arrivo",
          thStyle: { width: "120px" }
        },
        {
          key: "lastOrderDate",
          label: "Ultimo Acq.",
          thStyle: { width: "120px" }
        },
        { key: "costPrice", label: "Costo" },
        { key: "goodOnHand", label: "Giacenza" },
        { key: "totalRow", label: "Totale", sortable: true }
      ],
      items: [],
      brandId: null,
      brands: [],
      isBusy: false,
      filter: null,
      downloadPrice: false
    };
  },
  mounted() {
    this.getBrand();
  },
  watch: {
    brandId() {
      this.isBusy = true;
      this.items = [];
      this.getTurnover();
    },
    items(value) {
      if (value.length > 0) this.isBusy = false;
    }
  },
  computed: {
    itemsWithTotal() {
      let result = [];
      this.items.forEach(function(element, index) {
        result.push(element);
        result[index]["totalRow"] = element.costPrice * element.goodOnHand;
      });
      return result;
    },
    total() {
      return this.items.reduce((accum, item) => {
        return accum + item.costPrice * item.goodOnHand;
      }, 0);
    },
    totalCommitted() {
      return this.items.reduce((accum, item) => {
        return accum + item.costPrice * item.purchaseQuantity;
      }, 0);
    },
    brandName() {
      if (this.brandId)
        return this.brands.find(option => option.value === this.brandId)
          .brandName;
      return "";
    }
  },
  methods: {
    getBrand: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}brand-stocks`)
        .then(res => {
          this.brands = this.brandSelectAdapter(res.data);
        })
        .catch(err => {
          this.error = err;
        });
    },
    brandSelectAdapter: function(data) {
      let that = this;
      let result = [{ value: null, text: "Selezionare un brand" }];
      data.map(function(element) {
        result.push({
          value: element.id,
          text:
            element.name + " (" + that.currencyFormatter(element.total) + ")",
          brandName: element.name
        });
      });
      this.isBusy = false;
      return result;
    },
    getTurnover: function() {
      this.$http
        .get(
          `${process.env.VUE_APP_API_ENDPOINT}turnover/restocking?brandId=${this.brandId}`
        )
        .then(res => {
          this.items = res.data;
        })
        .catch(err => {
          this.error = err;
        });
    },
    currencyFormatter(number) {
      let currencyFormatter = new Intl.NumberFormat("it-IT", {
        style: "currency",
        currency: "EUR"
      });
      return currencyFormatter.format(number);
    },
    showDate(date) {
      if (date) return date.split(" ")[0];
      return "";
    }
  }
};
</script>
